<template>
  <el-table class="custom-user-table" :data="userList" border stripe>
    <el-table-column prop="imageUrl" label="头像" width="80px">
      <template slot-scope="{ row }">
        <img :src="row.imageUrl" alt="" style="width: 50px; height: 50px; border-radius: 5px;" />
      </template>
    </el-table-column>
    <el-table-column prop="showName" label="昵称" width="80px"></el-table-column>
    <el-table-column prop="memberType" label="用户类型" width="80px">
      <template slot-scope="scope">{{ scope.row.memberType | memberTypeLabel }}</template>
    </el-table-column>
    <el-table-column prop="userStatus" label="手机状态" width="80px">
      <template slot-scope="scope">
        {{ scope.row.mobileBind | mobileBindLabel }}
      </template>
    </el-table-column>
    <el-table-column prop="userStatus" label="微信状态" width="80px">
      <template slot-scope="scope">{{ scope.row.wxaBind | wxaBindLabel }}
      </template>
    </el-table-column>
    <el-table-column prop="mobile" label="电话" width="110px"></el-table-column>
    <el-table-column prop="registTime" label="首次使用日期" width="120px">
      <template slot-scope="scope">{{ scope.row.registTime | formatDate }}</template>
    </el-table-column>
    <el-table-column prop="realRegistTime" label="注册日期" width="120px">
      <template slot-scope="scope">{{ scope.row.realRegistTime | formatDate }}</template>
    </el-table-column>
    <el-table-column prop="registAddr" label="注册地/登录地"></el-table-column>
    <el-table-column prop="lastLoginTime" label="最后活跃时间">
      <template slot-scope="scope">{{ scope.row.lastActiveTime | formatDateTime }}</template>
    </el-table-column>
    <el-table-column prop="brand" label="设备品牌" width="100px"></el-table-column>
    <!--<el-table-column label="操作" width="100px">
      <template slot-scope="scope">
        <el-row>
          <el-button type="primary" icon="el-icon-view" size="mini"
                     @click="userDetail(scope.row.userId)">详情
          </el-button>
        </el-row>
      </template>
    </el-table-column>-->
  </el-table>
</template>

<script>
export default {
  name: 'UserTable',
  props: {
    userList: {
      type: Array,
      default () {
        return []
      }
    }
  },
  filters: {
    formatDate (value) {
      if (value === 0) {
        return ''
      }
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      return `${year}-${month}-${day}`
    },
    formatDateTime (value) {
      const date = new Date(value)
      const year = date.getFullYear()
      const month = (date.getMonth() + 1 + '').padStart(2, '0')
      const day = (date.getDate() + '').padStart(2, '0')
      const hour = (date.getHours() + '').padStart(2, '0')
      const minute = (date.getMinutes() + '').padStart(2, '0')
      const second = (date.getSeconds() + '').padStart(2, '0')
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`
    },
    memberTypeLabel (value) {
      switch (value) {
        case 0:
          return '游客'
        case 1:
          return '会员'
        default:
          return '游客'
      }
    },
    statusLabel (value) {
      switch (value) {
        case 1:
          return '正常'
        case 2:
          return '已退出'
        case 3:
          return '已注销'
        default:
          return '正常'
      }
    },
    mobileBindLabel (value) {
      switch (value) {
        case 1:
          return '已绑定'
        default:
          return '未绑定'
      }
    },
    wxaBindLabel (value) {
      switch (value) {
        case 1:
          return '已绑定'
        default:
          return '未绑定'
      }
    }
  },
  methods: {
    // 监听子组件发射的事件，获得最新的tradeList和total信息
    updateUserList () {
      this.$emit('user-list')
    },
    userDetail (userId) {
      this.alertMessage('TODO', 'warning')
    }
  }
}

</script>

<style scoped>

</style>
