<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>用户管理</template>
      <template v-slot:secondMenu>用户列表</template>
    </breadcrumb-nav>
    <el-card class="box-card">
      <!-- 顶部搜索与添加按钮 -->
      <user-search :listQuery="listQuery" @user-list="updateUserList"/>
      <!-- 展示的表格 -->
      <user-table :user-list="userList" :list-query="listQuery" @trade-list="updateUserList"/>
      <!-- 分页 -->
      <user-pagination :list-query="listQuery" :total="total" @page-size-change="handleSizeChange"
                        @current-page-change="handleCurrentChange"/>
    </el-card>
  </div>
</template>

<script>
import BreadcrumbNav from '../common/BreadcrumbNav'
import UserSearch from './userChildComponents/UserSearch'
import UserTable from './userChildComponents/UserTable'
import UserPagination from './userChildComponents/UserPagination'
import { getUserList } from '../../network/user'

export default {
  name: 'User',
  components: {
    BreadcrumbNav,
    UserSearch,
    UserTable,
    UserPagination
  },
  data () {
    return {
      listQuery: {
        memberType: '',
        mobileBind: '',
        wxaBind: '',
        date: '',
        page: 1,
        size: 10
      },
      userList: [],
      total: 0
    }
  },
  created () {
    this.getUserList()
  },
  methods: {
    updateUserList () {
      this.getUserList()
    },
    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.getUserList()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.getUserList()
    },
    getUserList () {
      const query = {
        page: this.listQuery.page,
        size: this.listQuery.size,
        memberType: this.listQuery.memberType,
        mobileBind: this.listQuery.mobileBind,
        wxaBind: this.listQuery.wxaBind
      }
      if (query.memberType === '') {
        query.memberType = -1
      }
      if (query.mobileBind === '') {
        query.mobileBind = -1
      }
      if (query.wxaBind === '') {
        query.wxaBind = -1
      }
      if (this.listQuery.date !== null && this.listQuery.date !== undefined && this.listQuery.date.length > 1) {
        query.from = this.listQuery.date[0]
        query.to = this.listQuery.date[1]
      }
      console.log('trade list query:', query)
      getUserList(query).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('交易列表获取失败', 'error')
        }
        this.userList = result.data.list
        for (const item of this.userList) {
          if (item.province === '0') {
            item.province = ''
          }
          if (item.city === '0') {
            item.city = ''
          }
          if (item.loginProvince === '0') {
            item.loginProvince = ''
          }
          if (item.loginCity === '0') {
            item.loginCity = ''
          }
          if (item.province !== '' && item.city !== '') {
            item.registAddr = item.province + '-' + item.city
          } else if (item.province !== '') {
            item.registAddr = item.province
          } else {
            if (item.loginProvince !== '' && item.loginCity !== '') {
              item.registAddr = item.loginProvince + '-' + item.loginCity
            } else if (item.loginProvince !== '') {
              item.registAddr = item.loginProvince
            } else {
              item.registAddr = ''
            }
          }
          if (item.registAddr === '') {
            item.registAddr = item.registIp
          }
          if (item.showAvatar.includes('http')) {
            item.imageUrl = item.showAvatar
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.showAvatar
          }
          if (item.wxaBind === 1 || item.mobileBind === 1) {
            item.memberType = 1
          } else {
            item.memberType = 0
          }
        }
        this.total = result.data.total
      })
    }
  }
}
</script>

<style scoped>

</style>
