<template>
  <div>
    <!-- 分页 -->
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="listQuery.page"
      :page-sizes="[5, 10]"
      :page-size="listQuery.size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: 'UserPagination',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          query: '',
          date: '',
          page: 1,
          size: 10
        }
      }
    },
    total: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {
    // 监听pageSize改变的事件，并发送新的网络请求
    handleSizeChange (newSize) {
      this.$emit('page-size-change', newSize)
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.$emit('current-page-change', newPage)
    }
  }
}
</script>

<style scoped>

</style>
