<template>
  <el-form :model="listQuery" ref="listQuery" :inline="true" class="demo-form-inline">
    <el-form-item label="用户类型" prop="memberType">
      <el-select v-model="listQuery.memberType" style="width:100%" @clear="cancelMemberType" clearable placeholder="选择用户类型">
        <el-option
          v-for="item in memberTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="手机绑定状态" prop="mobileBind">
      <el-select v-model="listQuery.mobileBind" style="width:100%" @clear="cancelMobileBindType" clearable placeholder="选择手机绑定状态">
        <el-option
          v-for="item in mobileBindTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="微信绑定状态" prop="wxaBind">
      <el-select v-model="listQuery.wxaBind" style="width:100%" @clear="cancelWxaBindType" clearable placeholder="选择微信绑定状态">
        <el-option
          v-for="item in wxaBindTypes"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="最后活跃日期范围" prop="date">
      <el-date-picker
        v-model="listQuery.date"
        type="daterange"
        align="right"
        prop="date"
        value-format="timestamp"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
      >
      </el-date-picker>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="search">查询</el-button>
      <el-button @click="resetForm('listQuery')">重置</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'UserSearch',
  props: {
    listQuery: {
      type: Object,
      default () {
        return {
          memberType: '',
          mobileBind: '',
          wxaBind: '',
          date: '',
          page: 1, // 当前页
          size: 10 // 每页显示多少条数据
        }
      }
    }
  },
  data () {
    return {
      memberTypes: [
        {
          label: '游客',
          value: 0
        },
        {
          label: '会员',
          value: 1
        }
      ],
      mobileBindTypes: [
        {
          label: '未绑定',
          value: 0
        },
        {
          label: '已绑定',
          value: 1
        }
      ],
      wxaBindTypes: [
        {
          label: '未绑定',
          value: 0
        },
        {
          label: '已绑定',
          value: 1
        }
      ]
    }
  },
  methods: {
    // 点击搜索按钮时，通知外部组件重新获取交易列表
    search () {
      this.listQuery.page = 1
      this.$emit('user-list')
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    cancelMemberType () {
      this.listQuery.memberType = ''
    },
    cancelMobileBindType () {
      this.listQuery.mobileBind = ''
    },
    cancelWxaBindType () {
      this.listQuery.wxaBind = ''
    }
  }
}
</script>

<style scoped>

</style>
